import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showIntro: true,
  showResult: false,
  showTrivia: false,
  score: 0,
  time: 30,
  currentQuestion: 0,
  correctAnswers: [],
};

export const gameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setShowIntro: (state, action) => {
      state.showIntro = action.payload;
    },

    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },

    setShowTrivia: (state, action) => {
      state.showTrivia = action.payload;
    },

    setScore: (state, action) => {
      state.score += action.payload;
    },

    setQuestionTime: (state, action) => {
      state.time = action.payload;
    },

    questionTimeReducer: (state) => {
      if (state.time >= 1) {
        state.time -= 1;
      }
    },

    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },

    setCorrectAnswers: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.correctAnswers.push(...action.payload);
      } else {
        state.correctAnswers.push(action.payload);
      }
    },

    resetGame: () => initialState,
  },
});

export const {
  setShowIntro,
  setShowResult,
  setShowTrivia,
  setScore,
  setQuestionTime,
  questionTimeReducer,
  setCurrentQuestion,
  setCorrectAnswers,
  resetGame,
} = gameSlice.actions;

export default gameSlice.reducer;
