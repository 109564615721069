// Import animation, motion
import { motion } from 'framer-motion';
import { containerVariants, child } from '../../utils/animations';

import { RiCheckFill, RiCloseFill } from 'react-icons/ri';

const Single = ({ data, clickHandlerSingle, isFormSubmitted, is_correct, images }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="single"
    >
      <motion.div variants={child} className="lead">
        <p className="fw-normal">{data.questionDescription}</p>
        <p className="fw-bold mb-1 py-2 px-2">{data.questionText}</p>
      </motion.div>
      <div className="row g-0 question-wrapper">
        <motion.div variants={child} className="question-image col-12 text-center">
          <img src={images[data.questionImage]} alt="question" />
        </motion.div>
        <motion.div variants={child} className="col-12 ">
          <div className="row g-0 answer-section">
            {data.answerOptions.map((answer, index) => (
              <div key={'single-answer_' + index} className="col-12 col-md-6 text-center">
                <button
                  id={'answer_' + index}
                  type="button"
                  className="btn btn-answer"
                  onClick={(e) => clickHandlerSingle(e, answer.is_correct)}
                  disabled={isFormSubmitted === true && 'disabled'}
                >
                  {answer.answerText}

                  <span className="animation animation__active"></span>

                  {isFormSubmitted && (
                    <>
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: is_correct ? 0 : 0.6 }}
                        className="animation animation__correct  "
                      >
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1 }}
                        >
                          <RiCheckFill className="icon" />
                        </motion.span>
                      </motion.span>
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="animation animation__wrong  "
                      >
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1 }}
                        >
                          <RiCloseFill className="icon" />
                        </motion.span>
                      </motion.span>
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Single;
