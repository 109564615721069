import { createSlice } from '@reduxjs/toolkit';
import { getAllUsers } from './actions';

const initialState = {
  showOptin: false,
  user: null,
  totalScore: null,
  correctAnswersTotal: [],
  users: [],
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setShowOptin: (state, action) => {
      state.showOptin = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setCorrectAnswersTotal: (state, action) => {
      if (action.payload === 'reset') {
        state.correctAnswersTotal = [];
      } else {
        state.correctAnswersTotal.push(action.payload);
      }
    },

    setTotalScore: (state, action) => {
      state.totalScore = action.payload;
    },

    setGuestToken: (state, action) => {
      state.guestToken = action.payload;
    },

    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setShowOptin, setUser, setCorrectAnswersTotal, setTotalScore, resetUser } =
  userSlice.actions;

export default userSlice.reducer;
