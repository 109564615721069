// Import animation, motion
import { motion } from 'framer-motion';
import { containerVariants, child } from '../../utils/animations';

const PointClickGlass = ({
  data,
  is_correct,
  isFormSubmitted,
  clickedAnswerIndex,
  clickHandlerGlass,
}) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="row g-0 glass"
    >
      <motion.div
        variants={child}
        className="lead col-12 col-md-5 text-center text-md-start mt-md-5"
      >
        <p className="fw-normal">{data.questionDescription}</p>
        <p className="fw-bold mb-1 py-2">{data.questionText}</p>
      </motion.div>
      <motion.div variants={child} className="col-12 col-md-7 answer-section">
        <svg
          width="309"
          height="450"
          viewBox="0 0 309 450"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fill-glass">
            {data.answerOptions.map((answer, index) => (
              <motion.path
                initial={{ fill: '#fff' }}
                animate={{
                  fill:
                    isFormSubmitted && index <= clickedAnswerIndex
                      ? clickedAnswerIndex === 3
                        ? '#BED1CF'
                        : '#BA4D56'
                      : '#fff',
                  transition: {
                    duration: 0.5,
                    delay:
                      isFormSubmitted && index <= clickedAnswerIndex
                        ? 1.2 - (clickedAnswerIndex - index) * 0.1
                        : 0,
                  },
                }}
                key={`answer-glass_${index}`}
                id={`answer_${index}`}
                d={answer.answerImg}
                fill={is_correct ? '#BED1CF' : '#BA4D56'}
                onClick={(e) => clickHandlerGlass(e, answer.is_correct)}
                style={{ cursor: isFormSubmitted ? 'none' : 'pointer' }}
                whileHover={!isFormSubmitted ? { fill: '#E1EBEA' } : {}}
              />
            ))}

            <g id="glass">
              <g id="2">
                <path
                  d="M136.792 412.159L143.788 412.051V415.063H132.592V412.327L138.1 407.575C139.192 406.627 139.996 405.703 139.996 404.539C139.996 403.447 139.348 402.775 138.088 402.775C136.78 402.775 136 403.411 136 405.127H132.592C132.592 401.047 135.472 399.871 138.232 399.871C140.92 399.871 143.608 400.975 143.608 404.359C143.608 406.915 141.76 408.475 139.936 409.735L136.792 412.159Z"
                  fill="#00594E"
                />
                <path
                  d="M156.25 400.863H157.65V415.063H156.41L156.32 413.963C155.55 414.743 154.52 415.263 153.27 415.263C150.79 415.263 149.73 413.383 149.73 410.063C149.73 406.803 150.79 404.943 153.25 404.943C154.47 404.943 155.5 405.453 156.28 406.203C156.26 405.973 156.25 405.733 156.25 405.563V400.863ZM153.43 414.063C154.55 414.063 155.59 413.323 156.25 412.583V407.623C155.59 406.883 154.55 406.143 153.43 406.143C151.61 406.143 151.13 407.823 151.13 410.103C151.13 412.423 151.59 414.063 153.43 414.063Z"
                  fill="#00594E"
                />
                <path
                  d="M163.985 415.263C161.065 415.263 159.845 413.403 159.845 410.103C159.845 406.783 161.185 404.943 164.065 404.943C166.465 404.943 167.765 406.223 167.765 408.163H166.365C166.365 406.743 165.365 406.123 164.005 406.123C162.065 406.123 161.245 407.503 161.245 410.083C161.245 412.743 162.105 414.083 164.025 414.083C165.385 414.083 166.365 413.483 166.365 412.043H167.765C167.765 413.963 166.465 415.263 163.985 415.263Z"
                  fill="#00594E"
                />
                <path d="M171.357 400.863V415.063H169.957V400.863H171.357Z" fill="#00594E" />
              </g>
              <g id="3">
                <path
                  d="M139.288 315.353C141.964 315.533 143.764 316.877 143.788 319.697C143.788 322.889 141.376 324.437 138.148 324.437C134.824 324.437 132.592 322.889 132.592 319.361H136C136 320.969 136.912 321.533 138.172 321.533C139.336 321.533 140.164 320.825 140.164 319.421C140.164 317.957 139.252 317.441 137.872 317.441H135.7V315.221L139.12 312.257H132.892V309.245H143.356V311.981L139.288 315.353Z"
                  fill="#00594E"
                />
                <path
                  d="M156.25 309.925H157.65V324.125H156.41L156.32 323.025C155.55 323.805 154.52 324.325 153.27 324.325C150.79 324.325 149.73 322.445 149.73 319.125C149.73 315.865 150.79 314.005 153.25 314.005C154.47 314.005 155.5 314.515 156.28 315.265C156.26 315.035 156.25 314.795 156.25 314.625V309.925ZM153.43 323.125C154.55 323.125 155.59 322.385 156.25 321.645V316.685C155.59 315.945 154.55 315.205 153.43 315.205C151.61 315.205 151.13 316.885 151.13 319.165C151.13 321.485 151.59 323.125 153.43 323.125Z"
                  fill="#00594E"
                />
                <path
                  d="M163.985 324.325C161.065 324.325 159.845 322.465 159.845 319.165C159.845 315.845 161.185 314.005 164.065 314.005C166.465 314.005 167.765 315.285 167.765 317.225H166.365C166.365 315.805 165.365 315.185 164.005 315.185C162.065 315.185 161.245 316.565 161.245 319.145C161.245 321.805 162.105 323.145 164.025 323.145C165.385 323.145 166.365 322.545 166.365 321.105H167.765C167.765 323.025 166.465 324.325 163.985 324.325Z"
                  fill="#00594E"
                />
                <path d="M171.357 309.925V324.125H169.957V309.925H171.357Z" fill="#00594E" />
              </g>
              <g id="4">
                <path
                  d="M142.059 227.692H143.919V230.608H142.059V233.188H138.675V230.608H131.583V227.956L138.675 218.308H142.059V227.692ZM134.751 227.692H138.675V222.364L134.751 227.692Z"
                  fill="#00594E"
                />
                <path
                  d="M156.262 218.988H157.662V233.188H156.422L156.332 232.088C155.562 232.868 154.532 233.388 153.282 233.388C150.802 233.388 149.742 231.508 149.742 228.188C149.742 224.928 150.802 223.068 153.262 223.068C154.482 223.068 155.512 223.578 156.292 224.328C156.272 224.098 156.262 223.858 156.262 223.688V218.988ZM153.442 232.188C154.562 232.188 155.602 231.448 156.262 230.708V225.748C155.602 225.008 154.562 224.268 153.442 224.268C151.622 224.268 151.142 225.948 151.142 228.228C151.142 230.548 151.602 232.188 153.442 232.188Z"
                  fill="#00594E"
                />
                <path
                  d="M163.997 233.388C161.077 233.388 159.857 231.528 159.857 228.228C159.857 224.908 161.197 223.068 164.077 223.068C166.477 223.068 167.777 224.348 167.777 226.288H166.377C166.377 224.868 165.377 224.248 164.017 224.248C162.077 224.248 161.257 225.628 161.257 228.208C161.257 230.868 162.117 232.208 164.037 232.208C165.397 232.208 166.377 231.608 166.377 230.168H167.777C167.777 232.088 166.477 233.388 163.997 233.388Z"
                  fill="#00594E"
                />
                <path d="M171.368 218.988V233.188H169.968V218.988H171.368Z" fill="#00594E" />
              </g>
              <g id="5">
                <path
                  d="M139.324 132.662C142.096 132.662 143.788 134.426 143.788 137.546C143.788 141.098 141.184 142.562 138.136 142.562C134.824 142.562 132.592 141.014 132.592 137.486H136C136 139.094 136.96 139.658 138.172 139.658C139.336 139.658 140.164 138.95 140.164 137.438C140.164 135.986 139.468 135.134 138.136 135.134C137.14 135.134 136.396 135.578 135.892 136.154L133.072 134.87L134.044 127.37H143.188V130.382L136.828 130.322L136.264 133.49C137.128 132.962 138.16 132.662 139.324 132.662Z"
                  fill="#00594E"
                />
                <path
                  d="M156.25 128.05H157.65V142.25H156.41L156.32 141.15C155.55 141.93 154.52 142.45 153.27 142.45C150.79 142.45 149.73 140.57 149.73 137.25C149.73 133.99 150.79 132.13 153.25 132.13C154.47 132.13 155.5 132.64 156.28 133.39C156.26 133.16 156.25 132.92 156.25 132.75V128.05ZM153.43 141.25C154.55 141.25 155.59 140.51 156.25 139.77V134.81C155.59 134.07 154.55 133.33 153.43 133.33C151.61 133.33 151.13 135.01 151.13 137.29C151.13 139.61 151.59 141.25 153.43 141.25Z"
                  fill="#00594E"
                />
                <path
                  d="M163.985 142.45C161.065 142.45 159.845 140.59 159.845 137.29C159.845 133.97 161.185 132.13 164.065 132.13C166.465 132.13 167.765 133.41 167.765 135.35H166.365C166.365 133.93 165.365 133.31 164.005 133.31C162.065 133.31 161.245 134.69 161.245 137.27C161.245 139.93 162.105 141.27 164.025 141.27C165.385 141.27 166.365 140.67 166.365 139.23H167.765C167.765 141.15 166.465 142.45 163.985 142.45Z"
                  fill="#00594E"
                />
                <path d="M171.357 128.05V142.25H169.957V128.05H171.357Z" fill="#00594E" />
              </g>
              <path
                id="outline"
                d="M235.395 449.2H72.105C60.8906 449.202 50.0947 444.942 41.9042 437.282C33.7136 429.623 28.7408 419.136 27.9932 407.947L0.855099 0.8H306.645L279.507 407.947C278.759 419.136 273.786 429.623 265.596 437.282C257.405 444.942 246.609 449.202 235.395 449.2Z"
                stroke="#00594E"
                strokeWidth="1.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="line"
                d="M6.5 89.063H301M13.125 181.875H294.375M18.75 270H288.75M26.25 360H281.25"
                stroke="#00594E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="4 4"
              />
            </g>
          </g>
        </svg>
      </motion.div>
    </motion.div>
  );
};

export default PointClickGlass;
