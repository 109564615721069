// Import react stuff
import { useState, useEffect } from 'react';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut } from '../../utils/animations';

// Import Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
  setCorrectAnswers,
  setCurrentQuestion,
  setScore,
  setShowTrivia,
  setQuestionTime,
  questionTimeReducer,
} from '../../store/gameSlice';
import { setShowOptin } from '../../store/userSlice';

// Import components
import Trivia from '../QuizTrivia/Trivia';
import Single from '../../components/question/Single';
import Multiple from '../../components/question/Multiple';
import CurrentGameStatus from '../../components/gameStatus/CurrentGameStatus';
import PointClickGlass from '../../components/question/PointClickGlass';
import PointClickHuman from '../../components/question/PointClickHuman';

const Quiz = ({ quizData, images }) => {
  const dispatch = useDispatch();
  const questions = quizData.content;
  const questionTime = 30;

  // Is form submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  //Multiple answers
  const [answersClicked, setAnswersClicked] = useState([]);
  const [areAnswersCorrect, setAreAnswersCorrect] = useState(null);
  const [clickedAnswerIndex, setClickedAnswerIndex] = useState(null);

  // Global states - RTK Toolkit
  const currentQuestion = useSelector((state) => state.games.currentQuestion);
  const correctAnswers = useSelector((state) => state.games.correctAnswers);
  const showTrivia = useSelector((state) => state.games.showTrivia);
  const questionTimeLeft = useSelector((state) => state.games.time);

  // Score and success rate calculations
  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  let totalQuestionsNum = questions?.length;

  // Handle single option answerđ
  const clickHandlerSingle = (e, is_correct) => {
    setIsFormSubmitted(true);

    // set clicked answer index
    setClickedAnswerIndex(parseInt(e.currentTarget.id.split('_')[1]));

    // set time to zero
    //dispatch(setQuestionTime(null));

    // Find correct answer
    let correctAnswerIndex = questions[currentQuestion].answerOptions.findIndex(
      (answer) => answer.is_correct === true
    );

    // find correct answer, clicked answer and list all buttons to clear classes on next step
    let answerCorrectBtn = document.getElementById(`answer_${correctAnswerIndex}`);
    let answerClickedBtn = document.getElementById(e.currentTarget.id);

    const pathIndex = parseInt(answerClickedBtn.id.split('_')[1]);

    // add correct or wrong class
    answerClickedBtn.classList.add(is_correct ? 'correct' : 'wrong');
    answerCorrectBtn.classList.add(!is_correct && 'correct');

    if (questions[currentQuestion].type === 'point-click') {
      let pathClicked = document.getElementById(`path_${pathIndex}`);

      pathClicked.classList.add('clicked');
      let correctPath = document.getElementById(`path_${correctAnswerIndex}`);

      correctPath.classList.add('correct');
    }

    // Add true/false to array and send to global state
    dispatch(setCorrectAnswers(is_correct));

    handleCheckForTrivia();
  };

  // Handle multiple option answerd
  const clickHandlerMultiple = (e) => {
    const answerId = e.target.id;

    if (answersClicked.includes(answerId)) {
      // If the answer is already selected, remove it from the selected answers
      setAnswersClicked((prevAnswers) => prevAnswers.filter((id) => id !== answerId));
    } else {
      // If the answer is not selected, add it to the selected answers
      setAnswersClicked((prevAnswers) => [...prevAnswers, answerId]);
    }
  };

  // On form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    // set time to zero
    //dispatch(setQuestionTime(null));

    // Find correct answer indices
    const correctAnswerIndices = questions[currentQuestion].answerOptions
      .map((answer, index) => (answer.is_correct ? index : -1))
      .filter((index) => index !== -1);

    // Check if the selected answers are correct
    const selectedAnswerIndices = answersClicked.map((answerId) =>
      parseInt(answerId.split('_')[1], 10)
    );
    const areAnswersCorrect = correctAnswerIndices.every((index) =>
      selectedAnswerIndices.includes(index)
    );

    // Boolean values
    const correctAnswersMulti = selectedAnswerIndices.map((index) =>
      correctAnswerIndices.includes(index)
    );

    // Update the correctAnswers array
    dispatch(setCorrectAnswers(correctAnswersMulti));

    // Find the correct answer button and clicked answer buttons
    const answerCorrectBtns = correctAnswerIndices.map((index) =>
      document.getElementById(`answer_${index}`)
    );
    const answerClickedBtns = answersClicked.map((answerId) => document.getElementById(answerId));

    // Add the correct or wrong class to the answer buttons
    answerClickedBtns.forEach((btn) => btn.classList.add(areAnswersCorrect ? 'correct' : 'wrong'));
    answerCorrectBtns.forEach((btn) => btn.classList.add(!areAnswersCorrect && 'correct'));

    handleCheckForTrivia();
  };

  const handleCheckForTrivia = () => {
    // Show trivia and handle next question after a timeout
    setTimeout(() => {
      dispatch(setShowTrivia(true));
    }, 3000);
  };

  // Handle trivia button click
  const handleTriviaClick = () => {
    dispatch(setShowTrivia(false));
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    setIsFormSubmitted(false);
    handleResetTimer();

    // Clear the selected answers
    setAnswersClicked([]);

    // clear clicked answer index
    setClickedAnswerIndex(null);

    // Remove classes for next questoon
    let answersAllButtons = document.querySelectorAll(
      '.answer-section button, .glass-question-wrapper button'
    );
    answersAllButtons.forEach(function (element) {
      element.classList.remove('correct');
      element.classList.remove('selected');
      element.classList.remove('wrong');
      element.classList.remove('clicked');
    });

    // move to next question
    let nextQuestion = currentQuestion + 1;

    // Move to next question or to optin
    if (nextQuestion < totalQuestionsNum) {
      dispatch(setCurrentQuestion(nextQuestion));
      window.scrollTo(0, 0);
    } else if (nextQuestion === totalQuestionsNum) {
      dispatch(setScore(correctAnswersNum));
      dispatch(setShowOptin(true));
    }
  };

  // Question timer
  const handleResetTimer = () => {
    // reset question time to initial value
    clearInterval(timer);
    dispatch(setQuestionTime(questionTime));
  };

  // time per question
  let timer;

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(questionTimeReducer());
    }, 1000);
    return () => clearInterval(timer);
  }, [dispatch, currentQuestion]);

  // When time's up and form is not submitted
  useEffect(() => {
    if (questionTimeLeft === 0 && !isFormSubmitted) {
      handleCheckForTrivia();
    }
  }, [questionTimeLeft]);

  return (
    <AnimatePresence>
      {!showTrivia ? (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="quiz wrapper inner-wrapper"
        >
          <CurrentGameStatus
            totalQuestionsNum={totalQuestionsNum}
            currentQuestion={currentQuestion}
            isFormSubmitted={isFormSubmitted}
            questionTimeLeft={questionTimeLeft}
            questionTime={questionTime}
          />

          <div className="question">
            {questions[currentQuestion].type === 'click-glass' ? (
              <PointClickGlass
                data={questions[currentQuestion]}
                clickHandlerGlass={clickHandlerSingle}
                isFormSubmitted={isFormSubmitted}
                images={images}
                clickedAnswerIndex={clickedAnswerIndex}
              />
            ) : questions[currentQuestion].type === 'point-click' ? (
              <PointClickHuman
                data={questions[currentQuestion]}
                clickHandlerHuman={clickHandlerSingle}
                isFormSubmitted={isFormSubmitted}
                images={images}
              />
            ) : questions[currentQuestion].type === 'multi-text' ? (
              <Multiple
                data={questions[currentQuestion]}
                clickHandlerMultiple={clickHandlerMultiple}
                isFormSubmitted={isFormSubmitted}
                images={images}
                handleSubmit={handleSubmit}
                answersClicked={answersClicked}
                areAnswersCorrect={areAnswersCorrect}
              />
            ) : (
              <Single
                data={questions[currentQuestion]}
                clickHandlerSingle={clickHandlerSingle}
                isFormSubmitted={isFormSubmitted}
                images={images}
              />
            )}
          </div>
        </motion.div>
      ) : (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="quiz wrapper inner-wrapper"
        >
          <div className="trivia">
            <Trivia
              data={questions[currentQuestion]}
              images={images}
              handleTriviaClick={handleTriviaClick}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Quiz;
