import React from 'react';

const TimerBar = ({ questionTimeLeft, isFormSubmitted, questionTime }) => {
  // Calculate the progress percentage
  const progress = ((questionTimeLeft / questionTime) * 100).toFixed(0);

  // Set the width percentage based on the progress value
  let progressBarStyle = {
    width: `${progress}%`,
    transition: 'width 1s linear',
    background: '#00594e',
  };

  // change style based on progress
  if (progress < 30 && !isFormSubmitted) {
    progressBarStyle = {
      ...progressBarStyle,
      background: '#b22c37',
    };
  }

  // console.log(questionTimeLeft);

  return (
    <div
      className="progress bg-transparent"
      style={{ height: '18px', borderRadius: '0px', width: '100%' }}
    >
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        style={progressBarStyle}
      >
        {/* {questionTimeLeft} */}
      </div>
    </div>
  );
};

export default TimerBar;
