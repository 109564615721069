// Import React stuff
import { useEffect } from 'react';

// Import Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { resetGame } from './store/gameSlice';
import { resetUser } from './store/userSlice';

// Import views, components
import Intro from './views/QuizIntro/Intro';
import Optin from './views/QuizOptin/Optin';
import Results from './views/QuizResults/Results';
import Quiz from './views/Quiz/Quiz';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';

// Import styles, icons, images
import './assets/styles/style.scss';

// Import data
import apiText from './data/apiText.json';
import apiData from './data/apiData.json';

// Import helper functions
import { importAll } from './utils/helpers';

const App = () => {
  const dispatch = useDispatch();

  const showIntro = useSelector((state) => state.games.showIntro);
  const showResult = useSelector((state) => state.games.showResult);
  const showOptin = useSelector((state) => state.users.showOptin);

  const images = importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg|gif)$/));

  // Reset states and clear localStorage when the tab is closed
  useEffect(() => {
    const handleAppClose = () => {
      // Clear sessionStorage when the tab/browser is closed
      sessionStorage.clear();
      dispatch(resetGame());
      dispatch(resetUser());
      localStorage.clear();
    };

    // Check if the page was reloaded by checking sessionStorage
    const isPageReloaded = sessionStorage.getItem('reloaded') != null;

    if (isPageReloaded) {
      //console.log('Page was reloaded');
    } else {
      //console.log('Page was not reloaded');
      // Add your specific actions for a new tab or initial page load here
    }

    // Set the sessionStorage to mark the page as reloaded
    sessionStorage.setItem('reloaded', 'yes');

    // Attach the beforeunload event listener only if the page is not reloaded
    if (!isPageReloaded) {
      window.addEventListener('beforeunload', handleAppClose);
    }

    return () => {
      // Remove the beforeunload event listener if it was attached
      if (!isPageReloaded) {
        window.removeEventListener('beforeunload', handleAppClose);
      }
    };
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
      transition={{ duration: 0.3 }}
      className="main-container"
    >
      <AnimatePresence mode="wait">
        {showOptin ? (
          <Optin key="optin" introData={apiText} />
        ) : showResult ? (
          <Results key="result" quizData={apiData} images={images} />
        ) : showIntro ? (
          <Intro key="intro" quizIntro={apiText} />
        ) : (
          <Quiz key="quiz" quizData={apiData} images={images} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default App;
