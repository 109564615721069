// Import redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { resetUser } from '../../store/userSlice';

// Import animation, motion
import { resetGame, setShowIntro, setShowResult } from '../../store/gameSlice';

// Import helper functions
import { shareContent } from '../../utils/helpers';

// Import images, icons
import logoEb from '../../assets/images/eb_logo.svg';

// Import animation, motion
import { motion } from 'framer-motion';
import {
  containerVariants,
  child,
  childSocial,
  resultVariants,
  fadeInOut,
} from '../../utils/animations';

// Import components
import Score from '../../components/Score';

const Results = ({ images, quizData }) => {
  const dispatch = useDispatch();

  //const score = parseInt(useSelector((state) => state.game.score));
  const correctAnswers = useSelector((state) => state.games.correctAnswers);
  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;

  // Calc success rate and points
  let successRate = Math.round((correctAnswersNum / 13) * 100);
  const points = correctAnswersNum;

  // SEGMENTS - set quiz segments
  let segmentName = null;
  const segment = quizData?.segments.find(
    (segment) => successRate >= segment.segmentScoreMin && successRate <= segment.segmentScoreMax
  );
  if (segment) {
    segmentName = segment.segment;
  }

  // Comparison
  const allUsers = useSelector((state) => state.users.users);

  const calculateSuccessAll = (users) => {
    const successRatesAll = users.map((user) => {
      const totalQuestions = 13;
      const correctAnswers = user.points;
      return Math.round((correctAnswers / totalQuestions) * 100);
    });

    return successRatesAll;
  };

  const successRatesAll = calculateSuccessAll(allUsers);

  // Calculate the percentage of games where the player's success rate is better
  const lessSuccessRates = successRatesAll.filter((rate) => rate < successRate);

  const betterThanPercent = Math.round((lessSuccessRates.length / successRatesAll.length) * 100);

  const playAgain = () => {
    dispatch(setShowResult(false));
    dispatch(setShowIntro(true));
    window.scrollTo(0, 0);
    window.plausible('play_again');
    setTimeout(() => {
      dispatch(resetGame());
      dispatch(resetUser());
    }, 1000);
  };

  const title = 'NAGRADNI KVIZ: Kako dobro zares poznate Donat?';
  const url = 'https://kviz.donat.com/';
  const image = 'https://kviz.donat.com/social_share.png';
  const text =
    'Sodeluj v kvizu ob 115. obletnici znamke Donat, ugotovi kako dobro ga zares poznaš in sodeluj v žrebu za super nagrade.';

  const onShareFacebook = () => {
    shareContent('facebook', title, url, text, image);
  };

  const onShareTwitter = () => {
    shareContent('twitter', title, url, text, image);
  };

  const onShareMessenger = () => {
    shareContent('messenger', title, url, text, image);
  };

  const onShareWhatsApp = () => {
    shareContent('whatsapp', title, url, text, image);
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={'results wrapper align-items-start align-items-md-center pb-5 p-md-0'}
    >
      <motion.img variants={child} className="eb-logo m-0" src={logoEb} alt="logo-eb" />
      <motion.div
        variants={resultVariants}
        initial="hidden"
        animate="visible"
        className="inner-wrapper text-center"
      >
        <>
          <div className="row g-0 text-start mb-2 mt-1  mb-md-4 mt-md-5">
            <motion.div variants={child} className="col-12 col-md-5 segment-image">
              <img src={images[segment?.segment_img]} alt={segmentName} />
            </motion.div>
            <div className="col">
              <motion.p variants={child} className="text-forest-green segment-descr">
                {segment?.segment_description}
              </motion.p>
              <div className="row g-0 mt-5">
                <motion.div variants={child} className="resultbar-wrap col col-md-4">
                  <Score points={points} percentage={successRate} />
                </motion.div>
                <div className="col ps-4 ps-md-3">
                  <motion.div variants={child} className=" pt-1 pt-md-3 ">
                    <p className="scorebar-descr">
                      Vaš rezultat je boljši od{' '}
                      <span className="text-forest-green fw-bold px-1">{betterThanPercent} %</span>{' '}
                      drugih uporabnikov, ki so rešili kviz.
                    </p>
                    <p className="scorebar-descr">Delite kviz in primerjajte rezultate.</p>
                  </motion.div>
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="share-wrapper w-100"
                  >
                    <motion.button
                      variants={childSocial}
                      onClick={() => onShareFacebook()}
                      className="share-button btn"
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.3153 2.63057L20.7132 2.625C16.2503 2.625 13.3674 5.58171 13.3674 10.1629V13.6361H8.75V19.9212H13.3674L13.3618 33.25H19.8223L19.8279 19.9212H25.126L25.1218 13.6375H19.8279V10.6905C19.8279 9.27341 20.1634 8.55651 22.0078 8.55651L25.3014 8.55511L25.3153 2.63057Z"
                          fill="#005BA2"
                        />
                      </svg>
                    </motion.button>
                    <motion.button
                      variants={childSocial}
                      onClick={() => onShareTwitter()}
                      className="share-button btn"
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="svg-path"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.1947 30.625C23.5766 30.625 30.3492 20.5245 30.3492 11.7664C30.3492 11.4789 30.3492 11.1935 30.3295 10.9092C31.647 9.97233 32.7839 8.8091 33.6879 7.47811C32.4607 8.01439 31.1564 8.36608 29.8226 8.52114C31.2269 7.6925 32.2786 6.39066 32.7823 4.85452C31.4599 5.62662 30.0145 6.17154 28.5068 6.46392C25.9572 3.79546 21.6932 3.66617 18.9812 6.17633C17.2339 7.79484 16.4907 10.208 17.0337 12.5098C11.6212 12.2417 6.57789 9.72509 3.15878 5.58512C1.37212 8.61377 2.28596 12.4868 5.24404 14.4316C4.1727 14.4009 3.12433 14.117 2.18752 13.6033V13.6874C2.18916 16.8421 4.44833 19.5584 7.58852 20.1835C6.59757 20.45 5.5574 20.4887 4.55005 20.2965C5.43107 22.9972 7.95931 24.8471 10.8386 24.9004C8.45478 26.745 5.50982 27.7467 2.47791 27.7435C1.94306 27.7418 1.40821 27.7111 0.875 27.6481C3.95449 29.5929 7.53602 30.625 11.1947 30.6202"
                          fill="#005BA2"
                        />
                      </svg>
                    </motion.button>
                    <motion.button
                      variants={childSocial}
                      onClick={() => onShareMessenger()}
                      className="share-button btn"
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="svg-path"
                          d="M17.5489 2.625C9.35337 2.625 2.625 9.01331 2.625 16.927C2.625 21.1438 4.57793 24.8893 7.59962 27.4981V33.25L13.1183 30.4906C14.5223 30.9133 15.9894 31.2291 17.5489 31.2291C25.7444 31.2291 32.4727 24.8408 32.4727 16.927C32.4727 9.01331 25.7444 2.625 17.5489 2.625ZM17.5489 5.11231C24.4618 5.11231 29.9854 10.4173 29.9854 16.927C29.9854 23.4368 24.4618 28.7418 17.5489 28.7418C16.0623 28.7418 14.6438 28.46 13.3127 28.0033L12.8074 27.8479L10.0869 29.2081V26.4099L9.62056 26.0213C6.8612 23.8449 5.11231 20.5851 5.11231 16.927C5.11231 10.4173 10.6359 5.11231 17.5489 5.11231ZM16.1497 13.0017L8.64895 20.93L15.3725 17.1991L18.948 21.0466L26.371 13.0017L19.803 16.6938L16.1497 13.0017Z"
                          fill="#005BA2"
                        />
                      </svg>
                    </motion.button>
                    <motion.button
                      variants={childSocial}
                      onClick={() => onShareWhatsApp()}
                      className="share-button btn"
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="svg-path"
                          d="M28.0381 6.4502C25.1738 3.5791 21.3594 2 17.3057 2C8.93848 2 2.12988 8.80859 2.12988 17.1758C2.12988 19.8486 2.82715 22.46 4.15332 24.7637L2 32.625L10.0459 30.5127C12.2607 31.7227 14.7559 32.3584 17.2988 32.3584H17.3057C25.666 32.3584 32.625 25.5498 32.625 17.1826C32.625 13.1289 30.9023 9.32129 28.0381 6.4502ZM17.3057 29.8018C15.0361 29.8018 12.8145 29.1934 10.8799 28.0449L10.4219 27.7715L5.65039 29.0225L6.92188 24.3672L6.62109 23.8887C5.35645 21.8789 4.69336 19.5615 4.69336 17.1758C4.69336 10.2236 10.3535 4.56348 17.3125 4.56348C20.6826 4.56348 23.8477 5.87598 26.2266 8.26172C28.6055 10.6475 30.0684 13.8125 30.0615 17.1826C30.0615 24.1416 24.2578 29.8018 17.3057 29.8018ZM24.2236 20.3545C23.8477 20.1631 21.9814 19.2471 21.6328 19.124C21.2842 18.9941 21.0312 18.9326 20.7783 19.3154C20.5254 19.6982 19.8008 20.5459 19.5752 20.8057C19.3564 21.0586 19.1309 21.0928 18.7549 20.9014C16.5264 19.7871 15.0635 18.9121 13.5938 16.3896C13.2041 15.7197 13.9834 15.7676 14.708 14.3184C14.8311 14.0654 14.7695 13.8467 14.6738 13.6553C14.5781 13.4639 13.8193 11.5977 13.5049 10.8389C13.1973 10.1006 12.8828 10.2031 12.6504 10.1895C12.4316 10.1758 12.1787 10.1758 11.9258 10.1758C11.6729 10.1758 11.2627 10.2715 10.9141 10.6475C10.5654 11.0303 9.58789 11.9463 9.58789 13.8125C9.58789 15.6787 10.9482 17.4834 11.1328 17.7363C11.3242 17.9893 13.8057 21.8174 17.6133 23.4648C20.0195 24.5039 20.9629 24.5928 22.166 24.415C22.8975 24.3057 24.4082 23.499 24.7227 22.6104C25.0371 21.7217 25.0371 20.9629 24.9414 20.8057C24.8525 20.6348 24.5996 20.5391 24.2236 20.3545Z"
                          fill="#005BA2"
                        />
                      </svg>
                    </motion.button>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <motion.div variants={child} className="mt-5 result-buttons">
            <a href="https://www.donat.com/sl/o-donatu/" target="_blank" rel="noopener noreferrer">
              <button
                type="button"
                className="btn btn-main"
                onClick={() => window.plausible('ctr')}
              >
                KONČAJTE KVIZ
              </button>
            </a>

            <button type="button" className="btn btn-again" onClick={playAgain}>
              IGRAJTE PONOVNO
            </button>
          </motion.div>
        </>
      </motion.div>
    </motion.div>
  );
};

export default Results;
