// Import Redux stuff
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Import reducers
import gameSlice from './gameSlice';
import userSlice from './userSlice';

const reducers = combineReducers({
  games: gameSlice,
  users: userSlice,
});

const store = configureStore({
  reducer: reducers,

  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware({
      serializableCheck: false,
    }).concat(),
});

export default store;
