// Import animation, motion
import { motion } from 'framer-motion';
import { containerVariants, child } from '../../utils/animations';

const Trivia = ({ data, images, handleTriviaClick }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="p-1 "
    >
      <motion.div variants={child} className="row g-0 d-flex align-items-center mb-3">
        <div className="col text-center">
          <img src={images[data.trivias.trivia_img]} alt="trivia" />
        </div>
        <div className="col-12 col-md-6 col-lg-7 trivia-text mt-3 mt-md-0">
          <p
            className=" px-2 px-md-0 m-0"
            dangerouslySetInnerHTML={{ __html: data.trivias.trivia_text }}
          ></p>
        </div>
      </motion.div>
      <motion.div
        variants={child}
        className="d-flex justify-content-center mt-1 mt-md-5 mb-5 mb-md-0"
      >
        <button type="button" className="btn btn-main" onClick={handleTriviaClick}>
          NAPREJ
        </button>
      </motion.div>
    </motion.div>
  );
};

export default Trivia;
