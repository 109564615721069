import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';

const API_ENDPOINT = isDevelopment ? 'http://localhost:3006' : 'https://kviz.donat.com';

const API = axios.create({
  baseURL: `${API_ENDPOINT}/api`,
  headers: {
    'Content-type': 'application/json',
  },
});

export default API;
