// Import icons, images
import close from '../../assets/images/icons/close.svg';
import prize from '../../assets/images/prize.jpg';

// Import animations
import { motion } from 'framer-motion';
import { child } from '../../utils/animations';

const Prizes = ({ handleClose }) => {
  return (
    <>
      <motion.button variants={child} className="btn exit-button" onClick={handleClose}>
        <img src={close} alt="close" />
      </motion.button>
      <motion.div variants={child} className="modal-content">
        <h1>NAGRADE</h1>
        <h2>Podelili bomo kar 40 nagrad:</h2>
        <div className="prize-wrapper">
          <div className="prize-media">
            <img src={prize} alt="prize" />
          </div>
          <div className="mt-4">
            <p className="prize-title fw-bold">
              Merjenje sestave telesa z interpretacijo izvida, v Medical Centru v Rogaški Slatini.
            </p>
            <p className="prize-description">
              Sodelujte v kvizu ob 115. obletnici blagovne znamke Donat in ugotovite, ali je vaše
              znanje dovolj poglobljeno, da sega do samega vrelca, kjer izvira Donat. S sodelovanjem
              lahko osvojite analizo sestave telesa, v sklopu katere prejmete informacije o količini
              telesnih tekočin, proteinov, mineralov, mase skeletnih mišic, delež telesnih maščob v
              vašem telesu itd., iz izmerjenih parametrov pa vam medicinsko osebje poda razlago
              rezultatov.
            </p>
            <p className="py-2 m-0">*Nakup izdelka ni pogoj za sodelovanje v nagradni igri.</p>
          </div>
        </div>

        <div className="btn-wrap">
          {' '}
          <button className="btn btn-lg w-100 btn-forest-green text-blue" onClick={handleClose}>
            ZAPRITE
          </button>
        </div>
      </motion.div>
    </>
  );
};

export default Prizes;
