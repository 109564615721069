// Import components
import TimerBar from './TimerBar';

const CurrentGameStatus = ({
  totalQuestionsNum,
  currentQuestion,
  isFormSubmitted,
  questionTime,
  questionTimeLeft,
}) => {
  return (
    <div
      className="current-game-status mb-5 position-absolute top-0 start-0 end-0"
      style={{ display: 'flex', width: '100%' }}
    >
      <div style={{ flex: '1' }}>
        <TimerBar
          questionTimeLeft={questionTimeLeft}
          questionTime={questionTime}
          isFormSubmitted={isFormSubmitted}
        />
      </div>
      <div className="text-forest-green current-question mx-3 mt-1">
        {currentQuestion + 1} / {totalQuestionsNum}
      </div>
    </div>
  );
};

export default CurrentGameStatus;
