// Import react stuff
import { useState } from 'react';

// Import redux stuff
import { useDispatch } from 'react-redux';
import { setShowIntro } from '../../store/gameSlice';
import { getAllUsers } from '../../store/actions';

// Import motion, animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../../utils/animations';

// Import icons, images
import imgUsp01 from '../../assets/images/icons/usp01.svg';
import imgUsp02 from '../../assets/images/icons/usp02.svg';
import imgUsp03 from '../../assets/images/icons/usp03.svg';
import intro from '../../assets/images/intro-donat.png';

// Import components
import Prizes from '../../components/prizes/Prizes';

const Intro = ({ quizIntro }) => {
  const dispatch = useDispatch();

  const startQuiz = () => {
    dispatch(setShowIntro(false));
    window.scrollTo(0, 0);
    dispatch(getAllUsers());
    window.plausible('start');
  };

  // Open/close prize modal
  const [openPrizeModal, setOpenPrizeModal] = useState(false);

  const closeModal = () => setOpenPrizeModal(false);
  const openModal = () => {
    window.plausible('nagrade');
    setOpenPrizeModal(true);
  };

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="intro wrapper"
      >
        {openPrizeModal ? (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="prizes"
            key={`prize`}
          >
            <Prizes handleClose={closeModal} openPrizeModal={openPrizeModal} />
          </motion.div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="d-flex row g-0 pb-5 pb-md-0 align-content-start align-content-md-center intro-wrap"
          >
            <motion.div variants={child} className="col-12 col-md-4 intro-img">
              <img src={intro} alt="intro-donat" className="h-100" />
            </motion.div>
            <motion.div
              variants={child}
              className="text-center text-md-start col-md-8 p-2 p-lg-4 content-wrap"
            >
              <h1 className="h1-welcome">
                <span dangerouslySetInnerHTML={{ __html: quizIntro.heading1 }}></span>
                <span className="label">{quizIntro.label}</span>
              </h1>
              <p className="intro-descr mb-2">{quizIntro.introDescription}</p>

              <div className="row g-0">
                <div className="col-4 usps">
                  <img className="icon" src={imgUsp01} alt={imgUsp01} />
                  <p>{quizIntro.usp1}</p>
                </div>
                <div className="col-4 usps">
                  <img className="icon" src={imgUsp02} alt={imgUsp02} />
                  <p>{quizIntro.usp2}</p>
                </div>
                <div className="col-4 usps">
                  <img className="icon" src={imgUsp03} alt={imgUsp03} />
                  <p>{quizIntro.usp3}</p>
                </div>
              </div>
              <div className="mt-1 mt-md-5">
                <button className="btn btn-main" onClick={startQuiz}>
                  {quizIntro.cta1}
                </button>
                <div className=" mt-0 mt-md-2 open-prize">
                  <span
                    className="mb-1 me-2"
                    onClick={() => (openPrizeModal ? closeModal() : openModal())}
                  >
                    {quizIntro.cta2}
                  </span>
                  <a
                    className="ms-3"
                    href="https://www.donat.com/sl/pravila-in-pogoji-sodelovanja-v-nagradni-igri-donat-115-let/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {quizIntro.conditions}
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Intro;
